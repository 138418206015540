export default [{
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
}, {
    title: 'Summary',
    route: 'summary-page',
    icon: 'TrendingUpIcon',
}, {
    title: 'Manage',
    route: 'manage-site',
    icon: 'GridIcon',
}, {
    title: 'Site Settings',
    route: 'site-settings',
    icon: 'SettingsIcon',
}, {
    title: 'Contacts',
    icon: 'MailIcon',
    children: [{
        title: 'Contact List',
        route: 'contacts',
    }, {
        title: 'Fortune Contacts',
        route: 'contacts-fortunewheel',
    },{
        title: 'TextApp Subscribers',
        route: 'textapp-subscribers',
    }],
}, {
    title: 'Reports',
    icon: 'TrelloIcon',
    children: [{
        title: 'Report',
        route: 'report',
    }, {
        title: 'Comparison Report',
        route: 'comparison-report',
    }, {
        title: 'Mobile Vs. Desktop',
        route: 'mobile-vs-desktop-report',
    }, {
        title: 'Drip Push Emails',
        route: 'drip-report',
    }],
}, {
    title: 'Take Screenshot',
    route: 'take-screenshot',
    icon: 'ImageIcon',
}, {
    title: 'Site Users',
    route: 'site-users',
    icon: 'UserCheckIcon',
}, {
    title: 'Add New Capture',
    route: 'add-new-capture',
    icon: 'PlusIcon',
}, {
    title: 'Add New Site',
    route: 'add-new-site',
    icon: 'PlusIcon',
}]